import React from 'react';

const UserStats = ({ co2Saved, points, onRedeemPoints }) => {
  // Calculate how many rides can be redeemed (assuming 100 points = 1 hour)
  const availableRides = Math.floor(points / 100);

  return (
    <div className="stats-container">
      <div className="stat-card">
        <div className="card-header">
          <div className="icon-title">
            <span className="icon">🌿</span>
            <h3>CO₂ Savings</h3>
          </div>
          <p className="description">Your total environmental impact</p>
        </div>
        <div className="card-content">
          <div className="value">{co2Saved?.toFixed(2) || '0'} kg</div>
          <p className="sub-text">
            That's equivalent to planting {Math.floor((co2Saved || 0) / 20)} trees!
          </p>
        </div>
      </div>

      <div className="stat-card">
        <div className="card-header">
          <div className="icon-title">
            <span className="icon">🏆</span>
            <h3>Reward Points</h3>
          </div>
          <p className="description">Earn points with every ride</p>
        </div>
        <div className="card-content">
          <div className="value">{points || 0} points</div>
          <div className="redeem-section">
            {availableRides > 0 ? (
              <>
                <p className="redeem-info">You can redeem 100 points for one free hour!</p>
                <button 
                  onClick={() => onRedeemPoints(100)}
                  className="form-b selected"
                >
                  Redeem 100 Points
                </button>
                <p className="rides-available">({availableRides} free hour{availableRides > 1 ? 's' : ''} available)</p>
              </>
            ) : (
              <p className="sub-text">
                Earn {100 - ((points || 0) % 100)} more points for a free hour!
              </p>
            )}
          </div>
        </div>
      </div>

      <style jsx>{`
        .stats-container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 1.5rem;
          margin-bottom: 1.5rem;
        }

        @media (max-width: 768px) {
          .stats-container {
            grid-template-columns: 1fr;
          }
        }

        .stat-card {
          background: white;
          border-radius: 8px;
          padding: 1.5rem;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          display: flex;
          flex-direction: column;
        }

        .card-header {
          margin-bottom: 1rem;
        }

        .icon-title {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          margin-bottom: 0.5rem;
        }

        .icon {
          font-size: 1.5rem;
        }

        h3 {
          font-size: 1.25rem;
          font-weight: 600;
          margin: 0;
        }

        .description {
          color: #666;
          font-size: 0.875rem;
          margin: 0;
        }

        .card-content {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
        }

        .value {
          font-size: 1.5rem;
          font-weight: bold;
          margin-bottom: 0.5rem;
        }

        .sub-text {
          color: #666;
          font-size: 0.875rem;
          margin: 0;
        }

        .redeem-section {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          margin-top: auto;
        }

        .redeem-info {
          color: #4a5568;
          margin-bottom: 0.75rem;
          font-size: 0.875rem;
        }

        .rides-available {
          color: #4a5568;
          margin-top: 0.5rem;
          font-size: 0.875rem;
        }

        button {
          width: 80%;  /* Reduced width for better aesthetics */
          margin: 0 auto;
        }
      `}</style>
    </div>
  );
};

export default UserStats;