import React, { useState, useEffect, useRef } from "react";
import '../App.css'; 
import DatePicker from "react-datepicker";
import { subDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate, NavLink } from 'react-router-dom';

const stripePromise = loadStripe("pk_live_51NgtgwA91KW339c4m9VPtbTVH0lrZOJh6TmGV0fuFMWYYLXV1JsNw319okGhzPwP4koaJm67Ynh7x6169WQJ7yhy00fUslHkbJ");

function Modal({ message, onClose }) {
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <p>{message || "No services available at this time. Please try a different time or date."}</p>
                <button onClick={onClose} className="modal-close-btn">Close</button>
            </div>
        </div>
    );
}

const BookingPage = () => {
  const [activeTab, setActiveTab] = useState('regular'); // 'regular' or 'featured'
  
  // States for regular booking
  const [pickupLocation, setPickupLocation] = useState('');
  const [dropoffLocation, setDropoffLocation] = useState('');
  const [date, setDate] = useState();
  const [rideDuration, setRideDuration] = useState("");
  const [totalPrice, setTotalPrice] = useState('$0.00'); 
  const [temp, setTemp] = useState('70');
  const [tempUnit, setTempUnit] = useState('F'); // 'F' for Fahrenheit, 'C' for Celsius
  const [selectedBev, setSelectedBev] = useState("Water");
  const [view, setView] = useState(false); 
  const [roundTrip, setRoundTrip] = useState(true);
  const [vehicleData, setVehicleData] = useState(null); 
  const [driverData, setDriverData] = useState(null); 
  const [checkoutSessionId, setCheckoutSessionId] = useState(null);
  const [pickupLocationError, setPickupLocationError] = useState('');
  const [dropoffLocationError, setDropoffLocationError] = useState('');
  const [rideDurationError, setRideDurationError] = useState('');
  const [dateError, setDateError] = useState('');
  const [tempError, setTempError] = useState('');
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [availabilityError, setAvailabilityError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [discountCode, setDiscountCode] = useState('');
  const [discountApplied, setDiscountApplied] = useState(false);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [hasFreeRide, setHasFreeRide] = useState(false);
  const [points, setPoints] = useState(0);

  // States for featured rides
  const [featuredRides, setFeaturedRides] = useState([]);
  const [loading, setLoading] = useState(false);
  const [featuredRideError, setFeaturedRideError] = useState(null);
  const [featuredPickupLocation, setFeaturedPickupLocation] = useState('');
  const [featuredPickupError, setFeaturedPickupError] = useState('');

  const navigate = useNavigate();

  // Book.js functions here
  const handleEdit = () => { 
        setView(false); // Return to form view
    };

    const roundToggle = () => {
        setRoundTrip(prev => !prev); // Toggle between roundTrip true/false
    };

    // shows dropoff location on second page 
    const showDrop = () => {
        return roundTrip ? pickupLocation : dropoffLocation;
    };

    useEffect(() => {
        // Check if user is signed in and fetch their status
        const email = localStorage.getItem('userEmail');
        if (!email) {
            navigate('/auth');
            return;
        }

        const fetchUserStatus = async () => {
            try {
                const response = await fetch(`https://p2un7v2kpumqt7hvflno62u26y0nvknz.lambda-url.us-east-1.on.aws/credential?email=${encodeURIComponent(email)}`);
                const data = await response.json();
                setHasFreeRide(!!data.has_free_ride);
                setPoints(data.points || 0); // Set the points state
            } catch (error) {
                console.error('Error fetching user status:', error);
            }
        };

        fetchUserStatus();
    }, [navigate]);

    const closeModal = () => {
        setIsModalOpen(false);
        setAvailabilityError(''); // Clear availability error when modal is closed
    };


    const handleDiscountAndCalculatePrice = async () => {
        if (!discountCode) {
            // If no discount code, just calculate regular price
            setTotalPrice(calculatePriceForDuration(rideDuration, 0));
            return;
        }

        try {
            const response = await fetch('https://66ycb6hzw6a3xcj4i2pl67pfcy0traln.lambda-url.us-east-1.on.aws/validate-discount', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ code: discountCode })
            });
            
            const data = await response.json();
            
            if (response.ok && data.Item) {
                const discountPercentage = data.Item.discount_percentage;
                setDiscountApplied(true);
                setDiscountPercentage(discountPercentage);
                setTotalPrice(calculatePriceForDuration(rideDuration, discountPercentage));
                setError('');
                console.log('Discount applied');
                setSuccessMessage(`Discount of ${discountPercentage}% applied successfully!`);
                // Clear success message after 3 seconds
                setTimeout(() => {
                    setSuccessMessage('');
                }, 3000);
            } else {
                setError('Invalid discount code');
                setDiscountApplied(false);
                setDiscountPercentage(0);
                setTotalPrice(calculatePriceForDuration(rideDuration, 0));
            }
        } catch (err) {
            console.error('Error validating discount:', err);
            setError('Error validating discount code');
            setDiscountApplied(false);
            setDiscountPercentage(0);
            setTotalPrice(calculatePriceForDuration(rideDuration, 0));
        }
    };

    const handleSubmit = async (event) => {
        console.log("In handleSubmit");
        event.preventDefault();
    
        setCheckoutSessionId(null); // Reset the session ID when submitting    

        const isAddressMatch = (returnedAddress, enteredAddress) => {
            const returnedComponents = returnedAddress.split(',').map(component => component.trim().toLowerCase());
            const enteredComponents = enteredAddress.split(',').map(component => component.trim().toLowerCase());
            return enteredComponents.some(component => returnedComponents.includes(component));
        };

        let isValid = true;

        if (!pickupLocation) {
            setPickupLocationError('Pickup location is required');
            isValid = false;
        } else {
            setPickupLocationError('');
            try {
                const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(pickupLocation)}`);
                const data = await response.json();
                if (data.length === 0 || !isAddressMatch(data[0].display_name, pickupLocation)) {
                    setPickupLocationError('Invalid address');
                    isValid = false;
                }
            } catch (error) {
                console.error('Error validating address:', error);
            }
        }

        if (!roundTrip && !dropoffLocation) {
            setDropoffLocationError('Dropoff location is required for a one-way trip.');
            isValid = false;
        } else {
            setDropoffLocationError('');
        }

        if (!rideDuration) {
            setRideDurationError('Ride Duration is required');
            isValid = false;
        } else {
            setRideDurationError('');
        }

        if (!date) {
            setDateError('Time and date are required');
            isValid = false;
        } else {
            setDateError('');
        }

        if (!temp) {
            setTempError('Temperature is required');
            isValid = false;
        } else {
            setTempError('');
        }

        console.log("Form is valid:", isValid); // Logs validation status
        
        if (!isValid) return; // Stop if form is invalid

        try {
            // Fetch availability and driver/vehicle data from the backend
            const response = await fetch(`https://66ycb6hzw6a3xcj4i2pl67pfcy0traln.lambda-url.us-east-1.on.aws/vehicle_driver_availability?rideDate=${date.toISOString()}&rideDuration=${rideDuration}`);
            const data = await response.json();
            console.log(data);
            if (!data.available) {
                const errorMessage = data.message || 'Ridio is fully booked at the time you selected. Please choose another time or day.';
                setAvailabilityError(errorMessage);
                setIsModalOpen(true);
                return;
            } else if (data.vehicle && data.driver) {
                setVehicleData(data.vehicle);
                setDriverData(data.driver);
                setAvailabilityError('');
                setIsFormSubmitted(true); // Set the form as submitted
                setView(true);
                console.log("Form submitted. isFormSubmitted:", isFormSubmitted, "Mode:", "view:", view); // Logs the state changes
            } else {
                setAvailabilityError('Unexpected error occurred. Please try again.');
                setIsModalOpen(true);
            }
        } catch (error) {
            setAvailabilityError('Error checking availability.');
            setIsModalOpen(true);
        }
    };

    useEffect(() => {
        if (vehicleData && driverData && isFormSubmitted) {
            createCheckoutSession(); // Proceed with checkout session after data is set
        }
    }, [vehicleData, driverData, isFormSubmitted]);

    useEffect(() => {
        if (rideDuration) {
            const price = calculatePriceForDuration(rideDuration, discountApplied ? discountPercentage : 0);
            console.log('Calculating price with:', {
                rideDuration,
                discountPercentage,
                hasFreeRide,
                result: price
            });
            setTotalPrice(price);
        }
    }, [discountPercentage, discountApplied, rideDuration, hasFreeRide]); // Added hasFreeRide to dependencies

    const createCheckoutSession = async () => {
        try {
            const email = localStorage.getItem('userEmail');
            if (!email) return;
    
            const response = await fetch('https://66ycb6hzw6a3xcj4i2pl67pfcy0traln.lambda-url.us-east-1.on.aws/checkout', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    email,  // Add this
                    pickupLocation,
                    dropoffLocation: roundTrip ? pickupLocation : dropoffLocation,
                    date: date.toISOString(), 
                    rideDuration,
                    temp,
                    selectedBev,
                    vehicleData,
                    driverData,
                    totalPrice,
                    discountCode: discountApplied ? discountCode : null
                })
            });
            const data = await response.json();
            if (data && data.id) {
                setCheckoutSessionId(data.id);
            }
        } catch (error) {
            console.error('Error creating checkout session:', error);
        }
    };

    const handleCheckout = async () => {
        try {
            const stripe = await stripePromise;
            if (!checkoutSessionId) {
                console.error('No checkout session ID available');
                return;
            }
            console.log('Redirecting to checkout with session ID:', checkoutSessionId);
            const { error } = await stripe.redirectToCheckout({
                sessionId: checkoutSessionId,
            });
            if (error) {
                console.error('Error redirecting to checkout:', error);
            }
        } catch (error) {
            console.error('Error in handleCheckout:', error);
        }
    };

    const calculatePriceForDuration = (duration, discountPercentage = 0) => {
        console.log('Price calculation inputs:', {
            duration,
            discountPercentage,
            hasFreeRide,
            freeHours: Math.floor(points / 100)
        });
    
        const basePrice = 60;
        const minPricePerHour = 30;
        let totalPrice = 0;
    
        const freeHours = Math.floor(points / 100);
    
        // If user has free hours
        if (hasFreeRide) {
            if (duration <= freeHours) {
                // All hours are free
                console.log('All hours are free');
                return '$0';
            }
    
            // Calculate price only for hours after the free hours
            for (let i = freeHours + 1; i <= duration; i++) {
                let hourPrice = basePrice * Math.pow(0.9, i - 1);
                hourPrice = Math.max(hourPrice, minPricePerHour);
                totalPrice += hourPrice;
            }
        } else {
            // Regular price calculation
            for (let i = 1; i <= duration; i++) {
                let hourPrice = i === 1 ? basePrice : basePrice * Math.pow(0.9, i - 1);
                hourPrice = Math.max(hourPrice, minPricePerHour);
                totalPrice += hourPrice;
            }
        }
    
        if (discountPercentage > 0) {
            totalPrice *= (1 - discountPercentage / 100);
        }
    
        return `$${Math.floor(totalPrice / 10) * 10}`;
    };

    const handleDurationChange = (event) => {
        const duration = event.target.value;
        setRideDuration(duration);
        setTotalPrice(calculatePriceForDuration(duration, discountApplied ? discountPercentage : 0)); 
    };

    const handleBevChange = (event) => {
        setSelectedBev(event.target.value);
    };

    // Add these conversion functions
    const celsiusToFahrenheit = (celsius) => {
        return (celsius * 9/5) + 32;
    };
    
    const fahrenheitToCelsius = (fahrenheit) => {
        return (fahrenheit - 32) * 5/9;
    };

    const ScrollToErrorMessage = ({ message }) => {
        const errorMessageRef = useRef(null);

        useEffect(() => {
            if (errorMessageRef.current) {
                errorMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }, [message]);

        return (
            <div style={{ color: 'red' }} ref={errorMessageRef}>
                {message}
            </div>
        );
    };

    const distance_travelled = rideDuration * 65;
    const gas_efficiency = 25.9;
    const gas_co2_created = 8887;
    const mach_e_efficiency = 0.33;
    const electricity_co2_created = 22.5;
    const grams_red = Math.floor((distance_travelled / gas_efficiency) * gas_co2_created) - ((distance_travelled / mach_e_efficiency) * electricity_co2_created);
    const kilo_round = (grams_red / 1000).toFixed(2);

  // Featured rides functions
    useEffect(() => {
        if (activeTab === 'featured') {
        fetchFeaturedRides();
        }
    }, [activeTab]);

    const fetchFeaturedRides = async () => {
        if (activeTab !== 'featured') return; // Don't fetch if not on featured tab
        
        setLoading(true);
        try {
          const response = await fetch('https://66ycb6hzw6a3xcj4i2pl67pfcy0traln.lambda-url.us-east-1.on.aws/featured-rides');
          const data = await response.json();
          setFeaturedRides(data.rides);
        } catch (err) {
          setFeaturedRideError('Failed to load featured rides');
        } finally {
          setLoading(false);
        }
    };

    const validateAddress = async (address) => {
        try {
        const response = await fetch(
            `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(address)}`
        );
        const data = await response.json();
        return data.length > 0;
        } catch (err) {
        console.error('Error validating address:', err);
        return false;
        }
    };

    const handleFeaturedBooking = async (ride) => {
        setFeaturedPickupError('');

        if (!featuredPickupLocation.trim()) {
          setFeaturedPickupError('Please enter a pickup location');
          return;
        }
    
        const isValidAddress = await validateAddress(featuredPickupLocation);
        if (!isValidAddress) {
          setFeaturedPickupError('Please enter a valid address');
          return;
        }
    

        try {
            const response = await fetch('https://66ycb6hzw6a3xcj4i2pl67pfcy0traln.lambda-url.us-east-1.on.aws/featured-rides', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ 
                rideId: ride.id,
                pickupLocation: featuredPickupLocation
              })
            });
      
            if (!response.ok) {
              const errorData = await response.json();
              throw new Error(errorData.message || 'Failed to book ride');
            }
      
            const { id: sessionId } = await response.json();
            const stripe = await stripePromise;
            await stripe.redirectToCheckout({ sessionId });
          } catch (err) {
            setFeaturedRideError(err.message || 'Failed to initiate booking');
          }
        };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        if (tab === 'featured' && featuredRides.length === 0) {
            fetchFeaturedRides();
        }
    };

  return (
    <div className='about-content'>
      <div className="booking-tabs">
        <button 
          className={`form-b ${activeTab === 'regular' ? 'selected' : 'unselected'}`}
          onClick={() => setActiveTab('regular')}
        >
          Book a Ride
        </button>
        <button 
          className={`form-b ${activeTab === 'featured' ? 'selected' : 'unselected'}`}
          onClick={() => setActiveTab('featured')}
        >
          Featured Rides
        </button>
      </div>

      {activeTab === 'regular' ? (
        // Regular booking form content
        <div>
          {isModalOpen && (
            <Modal message={availabilityError} onClose={() => setIsModalOpen(false)} />
          )}

          {view ? (
                // "view" refers to the second page, the "view" page
                // "view" starts out false, is made true by the validation function, then made false by the "edit" button, using the handleEdit function

                <div className="App-form">
                    <label className="form-el">
                        <h2>Pricing and Details:</h2>

                        <p>Ride Cost: {totalPrice}</p>
                        {hasFreeRide && (
                            <div className="free-ride-banner">
                                <p>
                                    🎉 You have {Math.floor(points / 100)} free hour{Math.floor(points / 100) > 1 ? 's' : ''} available!
                                    {rideDuration > Math.floor(points / 100) ? 
                                        ` Additional hours will be charged at regular rate.` : 
                                        ' Your entire ride will be free!'}
                                </p>
                                <style jsx>{`
                                    .free-ride-banner {
                                        border: 1px solid #91d5ff;
                                        border-radius: 4px;
                                        padding: 8px;
                                        margin: 8px 0;
                                    }
                                `}</style>
                            </div>
                        )}
                        <p>This covers {rideDuration} hour(s) of service with a grace period of :30.</p>
                        {pickupLocation !== '' && <p>Pickup Location: {pickupLocation}.</p>}
                        <p>Pickup time: {date ? date.toLocaleString() : ''}</p>
                        <p>Dropoff Location: {showDrop()}</p>
                        <p>Ride Duration: {rideDuration} hour(s)</p>
                        <p>Your preferred temperature is {temp}°F ({Math.round(fahrenheitToCelsius(temp))}°C)</p>
                        <p>Beverage: {selectedBev}</p>
                        {vehicleData && (
                            <>
                                <p>Vehicle: {vehicleData.vehicleMake} {vehicleData.vehicleModel}</p>
                                <p>Licence plate: {vehicleData.vehicleLicense}</p>
                            </>
                        )}
                        {driverData && (
                            <>
                                <p>Driver's Name: {driverData.driverName}</p>
                                <p>Driver's Phone: {driverData.driverPhone}</p>
                            </>
                        )}
                        <p>Your ride will save approximately {kilo_round} kilograms of CO2 Emissions compared to an average gas car on the same trip!</p>
                        <p>This is an estimate. Total emissions saved will be calculated after your trip has been completed.</p>
                    </label>

                    <label className="button-box second-page">
                        <button className="form-b selected" onClick={handleCheckout}>Book</button>
                        <button className="form-b unselected" onClick={handleEdit}>Edit</button>
                    </label>
                </div>
            ) : (
                <form className="App-form" onSubmit={handleSubmit}>
                    {/* <div style={{ display: toggle ? 'block' : 'none' }}>  */}
                        <label className="form-e">
                            <div className='form-label'>Ride Duration:</div>
                            <select value={rideDuration} className="i-box" onChange={handleDurationChange}>
                            <option className="select" value="">Please choose an option</option>
                            {[1, 2, 3, 4, 6, 8, '1day', '2day'].map((duration) => (
                                <option 
                                key={duration} 
                                value={typeof duration === 'string' ? (duration === '1day' ? 12 : 24) : duration}
                                >
                                {typeof duration === 'string' 
                                    ? `${duration.replace('day', ' Day')} - `
                                    : `${duration} Hour${duration === 1 ? '' : 's'} - `}
                                {calculatePriceForDuration(
                                    typeof duration === 'string' ? (duration === '1day' ? 12 : 24) : duration, 
                                    discountApplied ? discountPercentage : 0
                                )}
                                </option>
                            ))}
                            </select>
                        </label>

                        <label className="form-e">
                             <div className='form-label'>Discount Code:</div>
                        
                            <input
                                className="i-box"
                                type="text"
                                value={discountCode}
                                onChange={(e) => setDiscountCode(e.target.value)}
                            />
                            {error && <p className="error">{error}</p>}
                        </label>

                        <label className="button-box">
                            <button className="form-b selected confirm-b" type="button" onClick={handleDiscountAndCalculatePrice}>
                                Apply Discount
                            </button>
                            {error && <p className="error">{error}</p>}
                        </label>
                        
                        <label className="form-e">
                            <div className='form-label'>Pickup Location:</div>
                            <input
                                className="i-box"
                                value={pickupLocation}
                                placeholder='Please include punctuation'
                                onChange={e => setPickupLocation(e.target.value)}
                            />
                            {pickupLocationError && <p className="error-message">{pickupLocationError}</p>}
                        </label>

                        <label className="form-e">
                            <div className='form-label'>Pickup Date and Time:</div>
                            <DatePicker
                                className="i-box"
                                showTimeInput
                                minDate={(new Date())}
                                excludeDates={[new Date(), subDays(new Date())]}
                                selected={date}
                                onChange={(date) => setDate(date)}
                                dateFormat="MMMM d, yyyy h:mmaa"
                            />
                            {dateError && <p className="error-message">{dateError}</p>}
                        </label>

                        <label>
                            {/* roundToggle needs to switch between:
                            roundTrip (default)
                                have round trip button have "selected" className,
                                have "roundTrip" boolean save as True,
                                not show dropoffLocation input,
                                not require dropoffLocation for form validation
                            !roundTrip
                                have one way button have selected className
                                have roundtrip save as false
                                show dropoffLocation input
                                require dropoffLocation for form validation */}
                            {roundTrip ? (

                                <div>
                                    <label className="button-box">
                                        <div className="form-b selected round-way" onClick={roundToggle}>Round Trip</div>
                                        <div className="form-b unselected round-way" onClick={roundToggle}>One Way</div>
                                    </label>
                                </div>

                            ) : (

                                <div>
                                    <label className="button-box">
                                        <div className="form-b unselected round-way" onClick={roundToggle}>Round Trip</div>
                                        <div className="form-b selected round-way" onClick={roundToggle}>One Way</div>
                                    </label>

                                    <div className="form-e">
                                        <div className='form-label'>Dropoff Location:</div>
                                        <input
                                            className="i-box"
                                            value={dropoffLocation}
                                            onChange={e => setDropoffLocation(e.target.value)}
                                        />
                                        {dropoffLocationError && <p className="error-message">{dropoffLocationError}</p>}
                                    </div>
                                </div>
 
                            )}
                        </label>

                        <label className="form-e">
                            <div className='form-label'>Selected temperature:</div>
                            <div className="temp-input-container">
                                <input
                                className="i-box temp-input"
                                value={tempUnit === 'F' ? temp : Math.round(fahrenheitToCelsius(temp))}
                                onChange={e => {
                                    const value = e.target.value;
                                    if (tempUnit === 'F') {
                                    setTemp(value);
                                    } else {
                                    setTemp(Math.round(celsiusToFahrenheit(value)));
                                    }
                                }}
                                type="number"
                                min={tempUnit === 'F' ? "50" : "10"}
                                max={tempUnit === 'F' ? "90" : "32"}
                                />
                                <select 
                                className="i-box temp-unit-select"
                                value={tempUnit}
                                onChange={(e) => setTempUnit(e.target.value)}
                                >
                                <option value="F">°F</option>
                                <option value="C">°C</option>
                                </select>
                            </div>
                            {tempError && <p className="error-message">{tempError}</p>}
                         </label>

                        <label className="form-e">
                            <div className='form-label'>Complimentary Beverage:</div>
                            <select value={selectedBev} className="i-box" onChange={handleBevChange}>
                                <option value="Water">Water</option>
                                <option value="Iced Tea">Iced Tea</option>
                                <option value="Iced Coffee">Iced Coffee</option>
                            </select>
                        </label>
                        

                        <label className="b-box">
                            <button className="form-b selected confirm-b" type="submit">Confirm your ride?</button>
                        </label>
                </form>
            )}
        </div>
      ) : (
        // Featured rides content
        <div className='App-form'>
            <h2>Featured Rides</h2>
            {loading ? (
            <div>Loading featured rides...</div>
            ) : featuredRideError ? (
            <div className="error-message">{featuredRideError}</div>
            ) : (
            featuredRides.map((ride) => (
                <div key={ride.id} className="featured-ride-card">
                <h3>{ride.title}</h3>
                <p className="ride-description">{ride.description}</p>
                
                <div className="ride-details">
                    <p>
                    <strong>📅 Date:</strong> {new Date(ride.date).toLocaleDateString()} at {new Date(ride.date).toLocaleTimeString()}
                    </p>
                    
                    <p>
                    <strong>📍 Destination:</strong> {ride.dropoff_location}
                    </p>
        
                    <div className="form-e">
                    <div className='form-label'>Enter your pickup location:</div>
                    <input
                        className="i-box"
                        type="text"
                        value={featuredPickupLocation}
                        onChange={(e) => setFeaturedPickupLocation(e.target.value)}
                        placeholder="Enter full address with punctuation"
                    />
                    {featuredPickupError && <p className="error-message">{featuredPickupError}</p>}
                    </div>
                    
                    <p>
                    <strong>⏱️ Duration:</strong> {ride.duration} hours
                    </p>
                    
                    <p className="price-display">
                    <strong>💰 Price: </strong>
                    <span className="featured-price">${ride.price}</span>
                    {ride.original_price && (
                        <span className="original-price">${ride.original_price}</span>
                    )}
                    </p>
                </div>
                
                <div className="b-box">
                    <button
                    className="form-b selected"
                    onClick={() => handleFeaturedBooking(ride)}
                    >
                    Book This Ride
                    </button>
                </div>
                </div>
            ))
            )}
        </div>
      )}
    </div>
  );
};

export default BookingPage;